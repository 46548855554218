<template>
       <Dropdown v-model="selPeriodo" :options="lkpPeriodos" optionLabel="periodo" optionValue="periodo" placeholder="Seleccionar Periodo" @change="seleccionaFecha"/>
</template>

<script>	
	export default {
		name: 'SeleccionarFecha',
		props: {
			mostrar: {type: Boolean, required: false}
		},
		data() {
				return {
					selPeriodo: 'Hoy',
					nuevoPeriodo: {
						desde: new Date(),
						hasta: new Date()
					},
					lkpPeriodos:[
						{periodo: 'Hoy'},
						{periodo: 'Ayer'},
						{periodo: 'Esta Semana'},
						{periodo: 'Semana Pasada'},
						{periodo: 'Mes Actual'},
						{periodo: 'Mes Anterior'},
						{periodo: 'Año Actual'},				
						{periodo: 'Año Pasado'}								]
				}
		},
		methods: {
			seleccionaFecha(event) {
				var hoy = new Date();
				var first;
				var last;				
				switch(event.value) {
				case 'Hoy':
					this.nuevoPeriodo.desde = hoy;
					this.nuevoPeriodo.hasta = hoy;
					break;
				case 'Ayer':
					first = hoy.getDate() - 1;
					last = hoy.getDate() - 1;
					this.nuevoPeriodo.desde.setDate(first);
					this.nuevoPeriodo.hasta.setDate(last);
					break;
				case 'Esta Semana':
					first = hoy.getDate() - hoy.getDay(); // First day is the day of the month - the day of the week
					last = first + 6; // last day is the first day + 6

					this.nuevoPeriodo.desde = new Date(hoy.setDate(first));
					this.nuevoPeriodo.hasta = new Date(hoy.setDate(last));
					break;
				case 'Semana Pasada':					
					last = (hoy.getDate() - hoy.getDay()) -1; 
					first = last - 6; 

					this.nuevoPeriodo.desde = new Date(hoy.setDate(first));
					this.nuevoPeriodo.hasta = new Date(hoy.setDate(last));
					break;
				case 'Mes Actual':					
					this.nuevoPeriodo.desde = new Date(hoy.getFullYear(), hoy.getMonth(), 1);
					this.nuevoPeriodo.hasta = new Date(hoy.getFullYear(), hoy.getMonth() + 1, 0);
					break;
				case 'Mes Anterior':
					this.nuevoPeriodo.desde = new Date(hoy.getFullYear(), hoy.getMonth() - 1, 1);
					this.nuevoPeriodo.hasta = new Date(hoy.getFullYear(), hoy.getMonth(), 0);
					break;
				case 'Año Actual':
					this.nuevoPeriodo.desde = new Date(hoy.getFullYear(), 0, 1);
					this.nuevoPeriodo.hasta = new Date(hoy.getFullYear(), 11, 31);					
					break;
				case 'Año Pasado':
					this.nuevoPeriodo.desde = new Date(hoy.getFullYear() -1, 0, 1);
					this.nuevoPeriodo.hasta = new Date(hoy.getFullYear() -1, 11, 31);					
					break;
				}	

				this.$emit('nuevoPeriodo', this.nuevoPeriodo);
			}
		}
	}
</script>

<style scoped>

</style>
