<template>
	<div class="p-grid crud-demo">
		<div class="p-col-12">
			<div class="card" id="xyz">
				<h4 class="p-m-2">Historial de Consumos de {{ tipoConsumo }}</h4>
				<Toast/>
				<Toolbar class="p-mb-4">
					<template v-slot:left>
						<div class="p-fluid p-formgrid p-grid">
						<div class="p-field p-col-3">
							<label for="Periodo">Período</label>
							<SeleccionFecha @nuevoPeriodo="actualizarPeriodo"/>
						</div>
						<div class="p-field p-col-2 p-md-4">
							<label for="desde">Desde</label>
							<Calendar id="desde" v-model="desde" :key="desdeUpdate"/>
						</div>		
						<div class="p-field p-col-3 p-md-4">
							<label for="hasta">Hasta</label>
							<Calendar id="hasta" v-model="hasta" />
						</div>			
						<div class="p-field  p-col-6">
							<label for="servicio">Servicio</label>																																				
							<Dropdown v-model="tipo" :options="lkpTipos" optionLabel="contador_tipo" placeholder="Servicio..." />						
						</div>										
						<div class="p-field  p-col-6">
							<label for="inmueble">Inmueble</label>
							<div class="p-inputgroup">
								<InputText size="135" id="inmueble" type="text" v-model="InmuebleDisplay" disabled/>																							
								<Button v-if="mostrarBotonBusquedaCtaHabiente" icon="pi pi-search" @click="seleccionarInmueble"/>							
								<Button v-if="!mostrarBotonBusquedaCtaHabiente" icon="pi pi-times" class="p-button-warning" @click="seleccionarInmueble"/>																			
							</div>					
						</div>																				
						<div class="p-field p-col-2 p-md-2">
							<Button label="Buscar" icon="pi pi-search" class="p-button-primary p-m-2" @click="buscar" />
						</div>																							
					</div>	
					</template>
				</Toolbar>
				<DataTable ref="dt" :value="lecturas" class="p-datatable-customers" :rowClass="rowClass" dataKey="id_contador_lectura" :paginator="true" :rows="10" :filters="filters"
							:globalFilterFields="['numero_contador', 'inmueble']" 
							paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[5,10,25]"
							currentPageReportTemplate="Mostrando {first} de {last} de {totalRecords}" :loading="loading">
					<template #header>
						<div class="table-header p-d-flex p-flex-column p-flex-md-row p-jc-md-between">							
							<span class="p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global'].value" placeholder="Buscar..." />
                            </span>
							<span class="p-input-icon-right">								
								<Button :label="`Hay ${lecturasPendientesEmitir} consumos pendientes de emisión`" @click="confirmarEmisionCargos()" class="p-button-danger p-ml-2" icon="pi pi-cog" :style="existenLecturasSinEmitir? '' : 'display: none'"/>
                            </span>
						</div>
					</template>
					<Column field="numero_contador" header="Número Contador" :sortable="true">
						<template #body="slotProps">							
						<div v-if="slotProps.data.error" style="color: red">
							!
						</div>							
						<div>
							{{slotProps.data.numero_contador}}
						</div>							
						</template>
					</Column>
					<Column field="inmueble" header="Inmueble" :sortable="true"/>							
					<Column field="periodo_inicio" header="Período Inicio" :style="esAgua? '' : 'display: none'" :sortable="true">
						<template #body="slotProps">
							<span class="p-column-title">Período Inicio</span>
							{{slotProps.data.periodo_inicio}}
						</template>
					</Column>			
					<Column field="periodo_inicio" header="Período Fin" :style="esAgua? '' : 'display: none'"  :sortable="true">
						<template #body="slotProps">
							<span class="p-column-title">Período Fin</span>
							{{slotProps.data.periodo_fin}}
						</template>
					</Column>	
					<Column field="lectura_inicio" header="Lectura Inicio" :style="esAgua? '' : 'display: none'"  :sortable="true">
						<template #body="slotProps">
							<span class="p-column-title">Lectura Inicio</span>
							{{slotProps.data.lectura_inicio}}
						</template>
					</Column>	
					<Column field="lectura_fin" header="Lectura Fin" :style="esAgua? '' : 'display: none'" :sortable="true">
						<template #body="slotProps">
							<span class="p-column-title">Lectura Fin</span>
							{{slotProps.data.lectura_fin}}
						</template>
					</Column>		
					<Column field="costo_unidad" header="Costo Unidad" :style="esAgua? '' : 'display: none'" :sortable="true">
						<template #body="slotProps">
							<span class="p-column-title">Costo Unidad</span>
							{{slotProps.data.costo_unidad}}
						</template>
					</Column>		
					<Column field="costo_unidad_exceso" header="Costo Unidad Exceso" :style="esAgua? '' : 'display: none'"  :sortable="true">
						<template #body="slotProps">
							<span class="p-column-title">Costo U. Exceso</span>
							{{slotProps.data.costo_unidad_exceso}}
						</template>
					</Column>	
					<Column field="correlativo" header="Correlativo" :style="esEnergia? '' : 'display: none'" :sortable="true"/>			
					<Column field="servicio_contratado" header="Servicio Contr." :style="esEnergia? '' : 'display: none'" :sortable="true"/>			
					<Column field="potencia_contratada" header="Potencia Contr." :style="esEnergia? '' : 'display: none'" :sortable="true"/>			
					<Column field="mes_servicio" header="Período" :style="esEnergia? '' : 'display: none'" :sortable="true"/>			
					<Column field="fecha_lectura" header="Fecha Lectura" :style="esEnergia? '' : 'display: none'" :sortable="true"/>							
					<Column field="consumo_actual_kwh" header="Consumo Kwh" :style="esEnergia? '' : 'display: none'" :sortable="true"/>										
					<Column field="total_cargo" header="Total" :style="esEnergia? '' : 'display: none'" :sortable="true">
						<template #body="slotProps">
							{{ slotProps.data.moneda }} {{ formatCurrency(slotProps.data.total_cargo) }}
						</template>
					</Column>
					<Column field="documento" header="No.Docto" bodyStyle="text-align: center" :sortable="true">					
						<template #body="slotProps">	
							<div v-if="slotProps.data.id_movimiento">{{ slotProps.data.documento }}</div>													
							<Button v-if="!slotProps.data.id_movimiento" text="Remover" icon="pi pi-times" class="p-button-rounded p-button-danger p-mr-2" @click="confirmarRemoverLectura(slotProps.data)" />														
						</template>
					</Column>
				</DataTable>

				<BuscarCtaHabiente :key="dlgBuscarCtaHabienteRefresh" :mostrar="dlgBuscarCtaHabiente" @cancelar="cerrarDlgCtahabiente" @seleccionar="selCtaHabiente"/>
				<MdsBuscarInmueble :mostrar="inmuebleDialog" @cancelar="cerrarDlgInmueble" @seleccionar="elegirInmueble"/>
				
				<Dialog v-model:visible="removerDialog" :style="{width: '450px'}" header="Confirmar" :modal="true">
					<div class="confirmation-content">
						<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
						<span v-if="mov">Está seguro que desea remover la lectura seleccionda? <br><b>[{{mov.fecha_lectura}}]</b>?</span>
					</div>
					<template #footer>
						<Button label="No" icon="pi pi-times" class="p-button-text" @click="removerDialog = false"/>
						<Button label="Sí" icon="pi pi-check" class="p-button-text" @click="removerLect" />
					</template>
				</Dialog>

				<Dialog v-model:visible="dlgEnvioEmail" :modal="true">
					<template #header>
						Confirmación de envío de notificación
					</template>
					<div class="p-d-flex">
						<i class="pi pi-envelope p-mr-2" style="fontSize: 2rem"></i>
						<p>¿Está seguro que desea enviar los documentos seleccionado(s)?</p>
					</div>
					<template #footer>
						<div class="p-field p-col">
							<Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="dlgEnvioEmail=false"/>
							<Button label="Enviar" icon="pi pi-send" class="p-button-text" @click="enviarACtahabientesSeleccionados"/>
						</div>
					</template>
				</Dialog>	

				<Dialog v-model:visible="dlgNoHayEmails" :style="{width: '450px'}" header="No hay E-mails" :modal="true">
					<div class="confirmation-content">
						<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
						<span>No hay correos electronicos disponibles para esta cuenta!</span>
					</div>
					<template #footer>
						<Button label="Aceptar" icon="pi pi-times" class="p-button-text" @click="dlgNoHayEmails = false"/>
					</template>
				</Dialog>				

				<Dialog v-model:visible="enviandoEmailsDlg" :showHeader="true" :modal="true" :closable="false">			
					<div class="flex align-content-center flex-wrap card-container" :style="{width: '500px', height: '250'}">
						<div class="p-d-flex p-ai-center p-jc-center">
							<i class="pi pi-send p-m-2" style="font-size: 2rem"></i>
						</div>
						<div class="flex align-items-center justify-content-center mt-5">				
						<ProgressBar :value="secuencia">										
							<div class="font-light">
							Enviando {{secuencia}} de {{selectedConsumos.length}}
							</div>
						</ProgressBar>
						</div>
					</div>
				</Dialog>
			</div>
		</div>
	</div>
	<Dialog v-model:visible="emitirCargosXConsumoDlg" :style="{width: '1150px', height: '600px'}"  :showHeader="true" :modal="true" >
		<template #header>
			<div class="p-fluid">											
				<div class="p-fluid p-m-1">
					<h4 >
					Consumos pendientes de emisión
					</h4>				
				</div>				
			</div>
		</template>
		<div :style="{height: '425px'}">
			<DataTable ref="dt" :value="lecturasPendientes"  class="p-datatable-customers" v-model:selection="selectedConsumos" dataKey="id_contador_lectura" :paginator="false"  :filters="filters"
							paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[5,10,25]"
							currentPageReportTemplate="Mostrando {first} de {last} de {totalRecords}" :loading="loading">
				<Column selectionMode="multiple" headerStyle="width: 3rem"/>
				<Column field="error" header="Estado" bodyStyle="text-align: center">
					<template #body="slotProps">
						<i v-if="slotProps.data.en_proc" class="pi pi-spin pi-cog" style="font-size: 1.5rem"></i>
						<i v-if="slotProps.data.error" class="pi pi-times-circle" style="font-size: 1.5rem; color: red"></i>
						<i v-if="(!slotProps.data.error) && (slotProps.data.id_movimiento)" class="pi pi-check" style="font-size: 1.5rem;color: green"/>
					</template>
				</Column>
				<Column field="fecha" header="Fecha" :sortable="true"/>
				<Column field="numero_contador" header="Número Contador" :sortable="true"/>
				<Column field="inmueble" header="Inmueble" :sortable="true"/>
				<Column field="monto" header="Total" :sortable="true">
					<template #body="slotProps">
						{{ slotProps.data.moneda }} {{ formatCurrency(slotProps.data.monto) }}
					</template>
				</Column>
				<Column field="err_msg" header=""/>
					<template #body="slotProps">
						<div style="color: red">
							{{ slotProps.data.err_msg }}
						</div>
					</template>				
			</DataTable>
		</div>
		<template #footer>
			<div class="p-field p-col">
				<Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="emitirCargosXConsumoDlg=false"/>
				<Button label="Emitir Cargos" icon="pi pi-cog" class="p-button-text" @click="emitir()" :disabled="!hayCargosXEmitir"/>
			</div>
		</template>
	</Dialog>	

	<Dialog v-model:visible="procesandoDlg" :showHeader="true" :modal="true" :closable="false">			
		<div class="flex align-content-center flex-wrap card-container" :style="{width: '500px', height: '250'}">
			<div class="p-d-flex p-ai-center p-jc-center">
				<!-- <i class="pi pi-send p-m-2" style="font-size: 2rem"></i> -->
				<img src="/assets/layout/images/document.gif" width="64" height="64"/>
			</div>
			<div class="flex align-items-center justify-content-center mt-5">				
			<ProgressBar :value="secuencia">										
				<div class="font-light">
				Emitiendo {{secuencia}} de {{selectedConsumos.length}}...
				</div>
			</ProgressBar>
			</div>
		</div>
	</Dialog>
</template>

<script>
import {FilterMatchMode} from 'primevue/api';
import SeleccionFecha from '../../components/SeleccionFecha.vue';
import BuscarCtaHabiente from '../../components/BuscarCtaHabiente.vue';
import MdsBuscarInmueble from '../../components/MdsBuscarInmueble.vue';
import InvService from '../../service/inv.service';
import MdsService from '../../service/mds.service';
import { CURRENT_APP_MODE } from '../../service/constants';

export default {
	setup() {
		const rowClass = (data) => {
            return data.anulado === 'S' ? 'row-anulada': null;
        };

		return { rowClass }
	},	
	components: { SeleccionFecha, BuscarCtaHabiente, MdsBuscarInmueble},
	data() {
		return {
			appMode: CURRENT_APP_MODE,
			complejo: this.$store.state.auth.currentAppCtx.complejo,
			mostrar_anulados: false,
			dlgBuscarCtaHabiente: false,
			dlgBuscarCtaHabienteRefresh: 0,
			CtahabienteNombre: '(Todos)',
			id_cta_habiente_info: '0',
			desdeUpdate: 0,
			InmuebleDisplay: 'Seleccionar...',
			inmuebleDialog: false,
			tipo: null,
			lkpTipos: null,
			hasta: new Date(),
			desde: new Date(),	
			lecturas: null,
			existenLecturasSinEmitir: false,
			lecturasPendientesEmitir: 0,
			removerDialog: false,
			puedeAnular: this.$store.getters['auth/tieneAcceso']('CC_ANULAR'),
			puedeImprimir: this.$store.getters['auth/tieneAcceso']('CC_EMITIR_COMPRABANTE_PAGO'),
			puedeEnviarEmail: (this.$store.getters['auth/tieneAcceso']('CC_EMITIR_COMPRABANTE_PAGO')),			
			dlgEnvioEmail: false,
			dlgNoHayEmails: false,
			enviandoEmailsDlg: false,
			secuencia: 1,		
			procesandoDlg: false,
			selectedConsumos: null,	
			emitirCargosXConsumoDlg: false,
			emisionCompleta: false,
			lecturasPendientes: null,			
			emails: [],
			mov: {},	
			loading: false,
			filters: {'global': {value: null, matchMode: FilterMatchMode.CONTAINS}},
			submitted: false
		}
	},
	mounted() {
		this.lkpTipos = this.$store.state.auth.currentAppCtx.contadores_tipo;
		if(this.lkpTipos) {
			this.tipo = this.lkpTipos[0];
		}

		this.buscar();
	},
	computed: 
	{
		mostrarBotonBusquedaCtaHabiente(){
			return (this.id_cta_habiente_info == '0')
		},
		puedeEnviarEmails() {
			if(this.selectedConsumos)
			{
				return (this.selectedConsumos.length > 0) && this.puedeEnviarEmail;
			} else {
				return false;
			}
		},
		hayCargosXEmitir(){
			if(!this.selectedConsumos){
				return false;
			}
			return (this.selectedConsumos.length > 0) && !this.emisionCompleta;
		},
		esAgua() {
			if(!this.tipo) {
				return false;
			}
			return (this.tipo.id_contador_tipo == 1);
		},
		esEnergia() {
			if(!this.tipo) {
				return false;
			}
			return (this.tipo.id_contador_tipo == 2);
		},
		tipoConsumo() {
			if(this.tipo){
				return this.tipo.contador_tipo;
			} else {
				return '';
			}
		}
	},	
	methods: {
		formatDocument(Docto)
		{
			return Docto.toString().padStart(6, '0');
		},
		actualizarPeriodo(periodo)
		{
			this.desde = periodo.desde;
			this.hasta = periodo.hasta;
			this.periodoUpdate += 1;
		},
		buscarCtahabiente() {
			if(this.id_cta_habiente_info == '0'){								
				this.dlgBuscarCtaHabiente = true;
				this.dlgBuscarCtaHabienteRefresh++;
			} else {
				this.CtahabienteNombre = '(Todos)';
				this.id_cta_habiente_info = '0';		
			}
		},	
		cerrarDlgCtahabiente() {
			this.dlgBuscarCtaHabiente = false;
		},
		selCtaHabiente(CtaHabiente)
		{
			this.id_cta_habiente_info = CtaHabiente.id_cta_habiente_info;			
			this.CtahabienteNombre = CtaHabiente.nombre_comercial;	
			this.dlgBuscarCtaHabiente = false;
		},	
		seleccionarInmueble() {
			if(this.id_cta_habiente_info == '0'){								
				this.inmuebleDialog = true;
			} else {
				this.InmuebleDisplay = '(Todos)';
				this.id_cta_habiente_info = '0';		
			}			
		},
		cerrarDlgInmueble(){
			this.inmuebleDialog = false;
		},					
		elegirInmueble(Inmueble){
			this.id_cta_habiente_info = Inmueble.id_cta_habiente_info;			
			this.InmuebleDisplay = Inmueble.display;	
			this.CtahabienteNombre = Inmueble.nombre_documento;	
			this.inmuebleDialog = false;			
		},
		async buscar(){
			this.loading = true;
			
			var filtro = {
					id: this.complejo.id_complejo,
					id_cta_habiente_info: this.id_cta_habiente_info,
					id_tipo_doc: `${this.tipo.id_contador_tipo}`,
					desde: this.desde,
					hasta: this.hasta
			}

			try{			
				var data = await MdsService.getHistorialConsumo(this.$store.state.auth.currentAppCtx, filtro);
				this.lecturas = data;

				data = await MdsService.getTotalConsumosPendientesEmision(this.$store.state.auth.currentAppCtx, filtro);
				this.existenLecturasSinEmitir = (data.pendientes > 0);
				this.lecturasPendientesEmitir = data.pendientes;

				this.loading = false;				
			}catch (err) {
				this.lecturas = null;
				this.loading = false;
				this.$toast.add({severity:'error', summary: 'Error', detail: err, life: 3000})		
			}
		},
		formatCurrency(value) {
			return parseFloat(value).toLocaleString('en-US', {minimumFractionDigits: 2});
		},	  	
		hideDialog() {
			this.productDialog = false;
			this.submitted = false;
		},
		confirmarRemoverLectura(movimiento) {
			this.mov = movimiento;
			this.removerDialog = true;
		},
		async removerLect() {
			try {
				var data = await MdsService.removerLectura(this.$store.state.auth.currentAppCtx, this.mov.id_contador_lectura);

				this.removerDialog = false;
				this.lecturas = this.lecturas.filter(val => val.id_contador_lectura !== this.mov.id_contador_lectura);					
				this.mov = {};
				this.$toast.add({severity:'success', summary: 'Éxito', detail: 'La lectura ha sido eliminada!', life: 3000})

			} catch (err) {
				this.removerDialog = false;
				this.mov = {};
				this.$toast.add({severity:'error', summary: 'Error', detail: data, life: 3000})
			}
		},
		descargarPDF(movimiento) {	
			MdsService.getFormatoImpresionTrx(this.$store.state.auth.currentAppCtx, movimiento, false).then(
				response => {
					//Create a Blob from the PDF Stream
					const file = new Blob(
					[response.data], 
					{type: 'application/pdf'});
					//Build a URL from the file
					const fileURL = URL.createObjectURL(file);	

					var link = document.createElement('a');
					link.href = fileURL;
					link.download = `${movimiento.movimiento_tipo} ${movimiento.serie}-${movimiento.no_docto}.pdf`;
					link.dispatchEvent(new MouseEvent('click'));

					//Open the URL on new Window
					//window.open(fileURL);
				}				
			).catch(
				data => {
					this.anularDialog = false;
					this.mov = {};
					this.$toast.add({severity:'error', summary: 'Error', detail: data, life: 3000})
				}
			)
		},
		async enviarEmailTrx() {
			try {
				this.dlgEnvioEmail = false;
				this.loading = true;

				var notif = {						
					id: this.mov.id_movimiento,
					emails: this.emails
				};

				await MdsService.enviarEmailTrx(this.$store.state.auth.currentAppCtx, notif);

				this.loading = false;		
			} catch(error) {
				this.loading = false;
				this.$toast.add({severity:'error', summary: 'Error', detail: error, life: 3000})
			}			
		},
		async confirmarEnvioXEmail(movimiento) {
			try{				
				this.mov = movimiento;

				if(this.appMode == 1) { // Multidomus
					this.emails =  await MdsService.getInmueblesEmailsNotificacion(this.$store.state.auth.currentAppCtx, movimiento.id_cta_habiente_info);
				} else {
					this.emails = await InvService.getCtaHabienteEmailsNotificacion(this.$store.state.auth.currentAppCtx, movimiento.id_cta_habiente_info);
				}
				
				if(this.emails.length > 0) {
					this.dlgEnvioEmail = true;
				} else {
					this.dlgNoHayEmails = true;	
				}
			} catch(error) {
				this.loading = false;
				this.$toast.add({severity:'error', summary: 'Error', detail: error, life: 3000})
			}
		},		
		async enviarACtahabientesSeleccionados()
		{
			this.dlgEnvioEmail	= false;
			try
			{
				this.enviandoEmailsDlg = true;
				this.secuencia = 1;
				var notif;

				for(var i = 0; i < this.selectedConsumos.length; i++)
				{												
					notif = {						
						id: this.selectedConsumos[i].id_movimiento,
						emails: []
					};

					if(this.appMode == 1) { // Multidomus
						notif.emails =  await MdsService.getInmueblesEmailsNotificacion(this.$store.state.auth.currentAppCtx, this.selectedConsumos[i].id_cta_habiente_info);
					} else {
						notif.emails = await InvService.getCtaHabienteEmailsNotificacion(this.$store.state.auth.currentAppCtx, this.selectedConsumos[i].id_cta_habiente_info);
					}

					if(notif.emails.length > 0) {
						await CxcService.enviarEmailTrx(this.$store.state.auth.currentAppCtx, notif);
					}

					this.secuencia++;
				}
				this.secuencia = 1;
				this.enviandoEmailsDlg = false;
			}
			catch(error) {
				this.enviandoEmailsDlg = false;
				console.log(error);
			}
		},
		async confirmarEmisionCargos() {			
			this.loading = true;
			try{			
				var data = await MdsService.getConsumosPendientesEmision(this.$store.state.auth.currentAppCtx,	{
					id: this.complejo.id_complejo,
					id_tipo_doc: `${this.tipo.id_contador_tipo}`});
			
				this.lecturasPendientes = data;
				
				this.selectedConsumos = null;
				this.loading = false;	
				
				this.emisionCompleta = false;
				this.emitirCargosXConsumoDlg = true;							
			}catch (err) {
				this.lecturasPendientes = null;
				this.loading = false;
				this.$toast.add({severity:'error', summary: 'Error', detail: err, life: 3000})		
			}			
		},
		async emitir() {
			let i;
			let lectura;
			var indexPendientes = -1;
			var indexLecturas = -1;
			var data;

			this.secuencia = 0;
			this.procesandoDlg = true;
			this.emisionCompleta = true;
			try {
				for (i = 0; i < this.selectedConsumos.length; i++) {					
					try {
						lectura = this.selectedConsumos[i];						
						indexPendientes = this.findIndexConsumoPendiente(lectura.id_contador_lectura);
						this.secuencia++;

						try {
							this.lecturasPendientes[indexPendientes].en_proc = true;
							data = await MdsService.emitirCargoXConsumoContador(this.$store.state.auth.currentAppCtx, lectura);
						} catch(err) {
		
						}
										
						if(indexPendientes != -1) {
							this.lecturasPendientes[indexPendientes].error = data.error;
							this.lecturasPendientes[indexPendientes].err_msg = data.err_msg;
							this.lecturasPendientes[indexPendientes].en_proc = false;
							this.lecturasPendientes[indexPendientes].id_movimiento = data.id_movimiento;							
							
							indexLecturas = this.findIndexConsumo(data.id);
							if(indexLecturas !=-1) {
								this.lecturas[indexLecturas].id_movimiento = data.id_movimiento;
								this.lecturas[indexLecturas].documento = data.documento;
							}
						}

						//this.lecturas[index].emitido = true;
					} catch(error) {		
						this.$toast.add({severity:'error', summary: 'Error', detail: error, life: 3000})
					}
				}	
			} finally {
				this.procesandoDlg = false;
				this.secuencia = 0;
			}
		},		
		findIndexConsumoPendiente(id)
		{
			var index = -1;
			for(var i=0; i < this.lecturasPendientes.length; i++)
				if(this.lecturasPendientes[i].id_contador_lectura == id) {
					index = i;
					break;
			}

			return index;
		},
		findIndexConsumo(id)
		{
			var index = -1;
			for(var i=0; i < this.lecturas.length; i++)
				if(this.lecturas[i].id_contador_lectura == id) {
					index = i;
					break;
			}

			return index;
		},		
		exportCSV() {
			this.$refs.dt.exportCSV();
		}
	}
}
</script>

<style scoped lang="scss">
.anulado {
  color: #bd2130;
  font-size: small;
  font-weight: bold;
  text-align: center;
  margin: auto;  
}

.table-header {
	display: flex;
	justify-content: space-between;
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}
/* Responsive */
.p-datatable-customers .p-datatable-tbody > tr > td .p-column-title {
	display: none;
}

@media screen and (max-width: 960px) {
	::v-deep(.p-datatable) {
		&.p-datatable-customers {
			.p-datatable-thead > tr > th,
			.p-datatable-tfoot > tr > td {
				display: none !important;
			}

			.p-datatable-tbody > tr {
				border-bottom: 1px solid var(--surface-d);

				> td {
					text-align: left;
					display: block;
					border: 0 none !important;
					width: 100% !important;
					float: left;
					clear: left;
					border: 0 none;

					&:last-child {
						text-align: center;
					}

					.p-column-title {
						padding: .4rem;
						min-width: 30%;
						display: inline-block;
						margin: -.4rem 1rem -.4rem -.4rem;
						font-weight: bold;
					}

					.p-progressbar {
						margin-top: .5rem;
						display: inline-block;
						width: 60%;
					}

					.p-rating {
						display: inline-block;
					}
				}
			}

			.p-datatable-tbody > tr.p-rowgroup-footer{
				display: flex;
			}
		}
	}
}

::v-deep(.p-toolbar) {
	flex-wrap: wrap;
	.p-button {
		margin-bottom: .25rem;
	}
}

::v-deep(.row-anulada) {
    background-color: rgba(0,0,0,.15) !important;
}
</style>    

